import { FC } from 'react';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import { BlogProject } from 'src/models/blogPage';

import { useLinkWithUTMParams } from 'src/pages/Blog/components/SpecialProjects/hooks/useLinkWithUTMParams';

import styles from 'src/components/ArticleItem/article-item-compact.less';

interface BlogProjectItemProps {
    item: BlogProject;
}

const SpecialProjectsCompactItem: FC<BlogProjectItemProps> = ({ item }) => {
    const itemUrl = useLinkWithUTMParams(item.url);

    return (
        <div className={styles.compact}>
            <BlokoLink Element={SPALink} to={itemUrl} kind={LinkKind.Tertiary} title={item.title}>
                <div className={styles.compactPreviewContainer}>
                    {item.image && <img src={item.image} alt={item.title} className={styles.compactPreview} />}
                </div>
                <VSpacing base={3} />
                <Text>{item.title}</Text>
            </BlokoLink>
        </div>
    );
};

export default SpecialProjectsCompactItem;
