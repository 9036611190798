import { Fragment, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { ChevronDownOutlinedSize24, ChevronUpOutlinedSize24, MagnifierOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import modalHelper from 'bloko/common/modalHelper';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useToggleState } from 'src/hooks/useToggleState';
import { ArticleRubric } from 'src/models/articlePage/types';
import { BlogDictionaryItem, BlogTopic } from 'src/models/blogPage';
import BlogSubMenuLink from 'src/pages/Blog/components/BlogSubMenuLink';

import SearchForm from 'src/pages/Blog/components/Header/SearchForm';
import { useFocusOnAppearance } from 'src/pages/Blog/components/Header/useFocusOnAppearance';

import styles from './blog-header.less';

interface MobileMenuItem extends BlogDictionaryItem {
    topics?: BlogTopic[];
}

interface BlogMobileMenuProps {
    menuItems: MobileMenuItem[];
    rubric: ArticleRubric | null;
}

const TrlKeys = {
    title: 'blog.menu.title',
};

const BlogMobileMenu: TranslatedComponent<BlogMobileMenuProps> = ({ trls, menuItems, rubric }) => {
    const location = useLocation();
    const [isMenuOpen, toggleMenu, setIsMenuOpen] = useToggleState(false);
    const [isSubmenuOpen, toggleSubmenu] = useToggleState(true);
    const [isSearchOpen, toggleSearch, setIsSearchOpen] = useToggleState(false);
    const mainContentVisible = useSelector(({ mainContentVisible }) => mainContentVisible);
    const { isSearchEnabled } = useSelector(({ blogPage }) => blogPage);
    const focusOnAppearancRef = useFocusOnAppearance();
    const menuBarRef = useRef<HTMLDivElement>(null);

    const hasSubmenu = !!menuItems.find((rubricItem) => rubricItem.code === rubric)?.topics?.length;

    useEffect(() => {
        if (!mainContentVisible && isMenuOpen) {
            toggleMenu();
            modalHelper.enableScroll();
        }

        return () => {
            if (isMenuOpen) {
                modalHelper.enableScroll();
            }
        };
    }, [isMenuOpen, mainContentVisible, toggleMenu]);

    const handleNavItemMenuClick = () => {
        setIsSearchOpen(false);
        toggleMenu();

        if (isMenuOpen) {
            modalHelper.enableScroll();
        } else {
            modalHelper.disableScroll();
        }
    };

    const handleNavItemSearchClick = () => {
        setIsMenuOpen(false);
        toggleSearch();
    };

    const menuMarginTopToViewport = useMemo(() => {
        const menuBarElem = menuBarRef.current;
        return isMenuOpen && menuBarElem ? menuBarElem.getBoundingClientRect().top + menuBarElem.clientHeight : 110;
    }, [isMenuOpen]);

    const renderSubItem = (item: BlogTopic) => {
        if (!rubric) {
            return null;
        }

        const isActive = location.pathname.includes(item.code);
        const href = `/articles/${rubric}/${item.code}`;

        return (
            <div className={classnames(styles.menuItem, { [styles.menuItemActive]: isActive })} key={item.code}>
                <BlogSubMenuLink isActive={isActive} href={href}>
                    <Text>{item.name}</Text>
                </BlogSubMenuLink>
            </div>
        );
    };

    const renderTopLevelItem = (item: MobileMenuItem) => {
        const isActive = location.pathname.endsWith(item.code);
        const isTopicActive = !isActive && hasSubmenu && location.pathname.includes(`/${item.code}/`);
        const hasTopics = !!item.topics?.length;

        if (!isActive && !isTopicActive) {
            return (
                <div className={styles.menuItem} key={item.code}>
                    <BlokoLink
                        Element={SPALink}
                        disableVisited
                        to={item.code === '_blog_index' ? '/articles' : `/articles/${item.code}`}
                        kind={LinkKind.Tertiary}
                    >
                        <Text strong={!!rubric && hasSubmenu}>{item.name}</Text>
                    </BlokoLink>
                </div>
            );
        }

        return (
            <Fragment key={item.code}>
                <div className={classnames(styles.menuItem, { [styles.menuItemActive]: isActive })} key={item.code}>
                    <div className={styles.menuItemContent} onClick={toggleSubmenu}>
                        <Text strong={!!rubric && hasSubmenu}>{item.name}</Text>
                        {hasTopics &&
                            (isSubmenuOpen ? (
                                <BlokoIconReplaceContainer>
                                    <ChevronUpOutlinedSize24 />
                                </BlokoIconReplaceContainer>
                            ) : (
                                <BlokoIconReplaceContainer>
                                    <ChevronDownOutlinedSize24 />
                                </BlokoIconReplaceContainer>
                            ))}
                    </div>
                </div>
                {hasSubmenu && hasTopics && isSubmenuOpen && (
                    <div className={styles.submenu}>
                        {item.topics?.sort((a, b) => b.priority - a.priority).map(renderSubItem)}
                    </div>
                )}
            </Fragment>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.nav}>
                <div
                    onClick={handleNavItemMenuClick}
                    ref={menuBarRef}
                    className={classnames(styles.navItemMenu, {
                        [styles.navItemActive]: isMenuOpen,
                    })}
                >
                    {trls[TrlKeys.title]}
                </div>
                {isSearchEnabled && (
                    <div onClick={handleNavItemSearchClick} className={styles.navItemSearch}>
                        <BlokoIconReplaceContainer>
                            <MagnifierOutlinedSize24 initialColor={isSearchOpen ? 'accent' : 'primary'} />
                        </BlokoIconReplaceContainer>
                    </div>
                )}
            </div>
            {isMenuOpen && (
                <div
                    className={styles.menu}
                    style={{
                        height: `calc(100vh - ${menuMarginTopToViewport}px`,
                    }}
                >
                    <div className={styles.menuWrapper}>{menuItems.map(renderTopLevelItem)}</div>
                </div>
            )}
            {isSearchOpen && <SearchForm ref={focusOnAppearancRef} />}
        </div>
    );
};

export default translation(BlogMobileMenu);
